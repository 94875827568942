
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import ContactModal from "@/components/modals/ContactModal.vue";
import DonorModal from "@/components/modals/DonorModal.vue";
import TransportModal from "@/components/modals/TransportModal.vue";
import DonorFilters from "@/components/DonorFilters.vue";
import { GoogleMap, Marker } from "vue3-google-map";

export default defineComponent({
  name: "kt-widget-12",
  components: {
    DonorFilters,
    GoogleMap,
    Marker,
  },
  props: {
    widgetClasses: String,
    transportFilter: Number,
  },
  data() {
    return {
      key: process.env.VUE_APP_MAPS_API_KEY,
      mapStyle: [
        {
          featureType: "transit",
          stylers: [
            {
              color: "#808080",
            },
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],

      statusOptions: {
        active: "Activa",
        inactive: "Inactiva",
      },
      exportingData: "off",
      transportList: [],
      userList: [],
      donors: null,
      page: 1,
      perPage: 10,
      pages: null,
      count: null,
      filters: {
        type: [],
        status: "active",
        id: "",
        transport: "",
        string: "",
        visitorId: null,
        schedule: null,
      },
    };
  },
  methods: {
    getStatusClass(status) {
      switch (status) {
        case "active":
          return "svg-icon-success";
        case "inactive":
          return "svg-icon-danger";
        case "suspended":
          return "svg-icon-warning";
        default:
        case "irrecoverable":
          return "svg-icon-secondary";
      }
    },
    formatNumber(number) {
      return Number(number)
        .toString()
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
    },
    exportData() {
      this.exportingData = "on";
      ApiService.query("export/donors", {
        responseType: "blob",
        params: {
          filterTransport: this.filters.transport,
          filterType: this.filters.type,
          filterString: this.filters.string,
          filterId: this.filters.id,
          filterVisitor: this.filters.visitorId,
          filterStatus: this.filters.status,
          filterSchedule: this.filters.schedule,
          perPage: this.perPage,
          page: this.page,
        },
      }).then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "export.pdf";
        link.click();
        this.exportingData = "off";
      });
    },
    retrieveData() {
      ApiService.query("donors", {
        params: {
          markers: true,
          filterTransport: this.filters.transport,
          filterType: this.filters.type,
          filterString: this.filters.string,
          filterId: this.filters.id,
          filterVisitor: this.filters.visitorId,
          filterStatus: this.filters.status,
          filterSchedule: this.filters.schedule,
          perPage: this.perPage,
          page: this.page,
        },
      })
        .then((response) => {
          this.donors = response.data.data;
          this.count = response.data.total;
        })
        .catch((err) => {
          // TODO: Error Handling
        });
    },
  },
  mounted() {
    ApiService.get("index", "transports").then((response) => {
      this.transportList = response.data;
    });

    ApiService.query("index/users", {
      params: { permission: ["place_visit"] },
    }).then((response) => {
      this.userList = response.data;
    });

    if (this.$route.params.transportFilter) {
      this.filters.transport = this.$route.params.transportFilter.toString();
    }

    this.retrieveData();
  },
});
